<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image1.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">App Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 12 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">3 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">The Most Popular New Business Apps</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image2.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">Web Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 18 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">6 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">The Most Popular New Apps in 2024</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image3.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">Design Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 28 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">0 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">The Best Marketing Management Tools</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image4.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">App Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 12 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">3 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">It’s Time To Think Differently About App</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image5.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">Web Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 18 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">6 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">The Most Popular New Business Apps</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="assets/img/blog/image6.jpg" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">Design Development</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 28 March 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/single-blog">0 Comment</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">3 WooCommerce Plugins to Boost Sales</a></h3>
                        <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                        <a routerLink="/single-blog" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog" class="prev page-numbers"><i class="fa fa-chevron-left"></i></a>
                    <a routerLink="/blog" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="fa fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>