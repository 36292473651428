<div class="container">
    <div class="section-title">
        <h2>Our Expert Team</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/image1.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <p>Founder & CEO</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/image2.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Louis Agassiz</h3>
                        <p>Co-Founder</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/image3.jpg" alt="image">
                
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Carl Anderson</h3>
                        <p>Web Developer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>